.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 62px 32px 16px;
}

.app__content {
  display: flex;
  gap: 56px;
}

.app__logo {
  width: 169px;
  height: 68px;
  margin-top: 4px;
}

.app__title {
  display: none;
}

.app__text-list {
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  border: 1px solid var(--neutral-active);
  box-sizing: border-box;
  padding: 16px 24px;
}

.app__text-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-right: 1px solid var(--neutral-active);
  padding-right: 16px;
}

.app__text-item:last-child {
  border-right: none;
  padding-right: 0;
}

.app__text-item-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.22;
}

.app__contacts-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.app__contacts-link {
  display: flex;
  gap: 20px;
  transition: opacity 0.3s ease-in-out;
}

.app__contacts-link:hover {
  opacity: 0.85;
}

.app__contacts-icon-box {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--neutral-active);
}

.app__contacts-item:first-child .app__contacts-icon-box {
  border: none;
}

.app__contacts-text {
  font-size: 19px;
  font-weight: 400;
  line-height: 1.22;
  padding-top: 6px;
}

.app__banner {
  width: 1314px;
  position: absolute;
  top: 16px;
  margin-left: -58px;
  z-index: -1;
}

@media screen and (max-width: 900px) {
  .app {
    padding: 58px 32px 16px;
  }

  .app__content {
    width: 100%;
    max-width: 624px;
    display: grid;
    grid-template-areas: 'logo contacts' 'text contacts';
    justify-content: space-between;
    gap: 40px;
  }

  .app__logo {
    grid-area: logo;
    width: 139px;
    height: 56px;
    margin-top: 0;
    margin-left: 20px;
  }

  .app__text-list {
    grid-area: text;
  }

  .app__contacts-list {
    grid-area: contacts;
    margin-top: 6px;
  }

  .app__contacts-link:hover {
    opacity: 1;
  }

  .app__banner {
    width: 1202px;
    top: 90px;
    margin-left: -28px;
  }
}

@media screen and (max-width: 600px) {
  .app {
    padding: 40px 16px 16px;
  }

  .app__content {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .app__logo {
    margin-left: 0;
  }

  .app__contacts-list {
    margin-top: 0;
  }

  .app__banner {
    width: 830px;
    top: 272px;
    margin-left: 0;
  }
}
