body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  font-style: normal;
  white-space: pre-line;
}

span {
  color: inherit;
  white-space: inherit;
}

a {
  font-style: normal;
  text-decoration: none;
  color: var(--neutral-active);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

:root {
  --website-background: #000000;
  --neutral-active: #FFFFFF;
  --whatsapp-icon-color: #16A250;
}
